import { ChainId } from "@usedapp/core";

import { Contract, SupportedChainId } from "./types";

export type ContractConfig = Readonly<Record<Contract, string>>;

export const CONTRACT_ADDRESSES: Record<SupportedChainId, ContractConfig> = {
  [ChainId.Mainnet]: {
    // presale: "0xBa9Ea6734Be25191e835480bd97eF4e94a196890",
    sale: "0xBa9Ea6734Be25191e835480bd97eF4e94a196890",
    arbitrageur: "0xcF21AD4190658f4E0FE015fC7E056eC970384435",
    daw: "0xF1268733C6FB05EF6bE9cF23d24436Dcd6E0B35E",
    bayc: "0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D",
    wowmax: ""
  },
  [ChainId.BSC]: {
    // presale: "0x66a3ABc193818f6A593b6dc490E2989EB2C01133",
    sale: "0x66a3ABc193818f6A593b6dc490E2989EB2C01133",
    arbitrageur: "0x831732a174b7d0D5fd4f2DF269B2D42687e1Cf71",
    bayc: "",
    daw: "",
    wowmax: "0x2329830B1D60D03CC7d7F6c8687c504994f6a596"
  },
  [ChainId.Avalanche]: {
    // presale: "0x2EE88bfeFA2992bd202f558a8a546b19B44Fc8a4",
    sale: "0x2EE88bfeFA2992bd202f558a8a546b19B44Fc8a4",
    arbitrageur: "0x305a568c5931E7D306e007D2379E8EE5a7785bC3",
    daw: "",
    bayc: "",
    wowmax: ""
  },
  [ChainId.Polygon]: {
    // presale: "0x7Bbc3124788b8CA508CD68BED7c1b319b309712F",
    sale: "0x7Bbc3124788b8CA508CD68BED7c1b319b309712F",
    arbitrageur: "0x1207dBc73f1b95A4a0fd8FFfb3f4cB785493A344",
    daw: "",
    bayc: "",
    wowmax: ""
  },
  [ChainId.Hardhat]: {
    sale: "0x7Bbc3124788b8CA508CD68BED7c1b319b309712F",
    arbitrageur: "0x1207dBc73f1b95A4a0fd8FFfb3f4cB785493A344",
    daw: "",
    bayc: "",
    wowmax: "0x948B3c65b89DF0B4894ABE91E6D02FE579834F8F"
  }
};
