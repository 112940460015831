exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-alpha-tsx": () => import("./../../../src/pages/alpha.tsx" /* webpackChunkName: "component---src-pages-alpha-tsx" */),
  "component---src-pages-crew-tsx": () => import("./../../../src/pages/crew.tsx" /* webpackChunkName: "component---src-pages-crew-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-public-pre-sale-tsx": () => import("./../../../src/pages/public_pre_sale.tsx" /* webpackChunkName: "component---src-pages-public-pre-sale-tsx" */),
  "component---src-pages-public-sale-tsx": () => import("./../../../src/pages/public_sale.tsx" /* webpackChunkName: "component---src-pages-public-sale-tsx" */),
  "component---src-pages-to-wow-holders-tsx": () => import("./../../../src/pages/to_wow_holders.tsx" /* webpackChunkName: "component---src-pages-to-wow-holders-tsx" */),
  "component---src-pages-token-distribution-tsx": () => import("./../../../src/pages/token_distribution.tsx" /* webpackChunkName: "component---src-pages-token-distribution-tsx" */)
}

