import { BaseProvider } from "@ethersproject/providers";
import { Avalanche, BSC, Chain, ChainId, Hardhat, Mainnet, Polygon } from "@usedapp/core";

import { ContractConfig, CONTRACT_ADDRESSES } from "./contracts";
import { TOKENS, TokenInfo } from "./tokens";
import { SaleChartAsset, SupportedChainId } from "./types";

type BaseProviderFactory = () => BaseProvider;

type ChainConfig = {
  chainId: SupportedChainId;

  wutDecimals: number;
  usdcDecimals: number;
  start: number;
  closeDeposit: number;
  end: number;
  interval: number;
  chain?: Chain;
  rpc: string | BaseProvider | BaseProviderFactory;
  graph: string;

  contracts: ContractConfig;
  tokens?: Readonly<Record<string, string>>;

  alpha: {
    pair: string;
    tokens: {
      in: TokenInfo;
      out: TokenInfo;
    };

    DEX?: {
      quickswap?: string;
      uniswapv3Quoter?: string;
      uniswapv3?: string;
      sushiswap?: string;
      pangolin?: string;
      traderJoe?: string;
      pancake?: string;
      biswap?: string;
    };
  };

  sale: {
    tokens: {
      assets: TokenInfo[];
      deposited: TokenInfo[];
    };
    chart: {
      assets: Readonly<Record<SaleChartAsset, string>>;
    };
  };
};

const graphUrl = (network: string) =>
  `https://api.thegraph.com/subgraphs/name/wowswap-io/wowmax-presale-${network}`;

export const CHAINS: Record<SupportedChainId, ChainConfig> = {
  [ChainId.Mainnet]: {
    chainId: ChainId.Mainnet,

    start: 1660251600,
    end: 1665723600,
    closeDeposit: 1662872400,
    interval: 60 * 240, // 30 min
    rpc: "https://nodes-ethereum-https.wowswap.io",
    graph: graphUrl("mainnet"),

    wutDecimals: 18,
    usdcDecimals: 6,

    contracts: CONTRACT_ADDRESSES[ChainId.Mainnet],

    alpha: {
      // USDC-ETH pair uniswap
      pair: "0xB4e16d0168e52d35CaCD2c6185b44281Ec28C9Dc",
      tokens: {
        in: { ...TOKENS.wrapNative[ChainId.Mainnet], symbol: "ETH" },
        out: TOKENS.usdc[ChainId.Mainnet]
      },
      DEX: {
        uniswapv3Quoter: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
        uniswapv3: "0xE592427A0AEce92De3Edee1F18E0157C05861564",
        sushiswap: "0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F"
      }
    },

    sale: {
      tokens: {
        assets: [TOKENS.dai[ChainId.Mainnet], TOKENS.usdt[ChainId.Mainnet]],
        deposited: [
          TOKENS.dai[ChainId.Mainnet],
          TOKENS.usdt[ChainId.Mainnet],
          TOKENS.usdc[ChainId.Mainnet],
          TOKENS.wow[ChainId.Mainnet],
          TOKENS.oneInch[ChainId.Mainnet]
        ]
      },
      chart: {
        assets: {
          wow: TOKENS.wow[ChainId.Mainnet].address,
          busd: TOKENS.busd[ChainId.Mainnet].address,
          usdc: TOKENS.usdc[ChainId.Mainnet].address,
          usdt: TOKENS.usdt[ChainId.Mainnet].address,
          dai: TOKENS.dai[ChainId.Mainnet].address,
          oneInch: TOKENS.oneInch[ChainId.Mainnet].address,
          daw: CONTRACT_ADDRESSES[ChainId.Mainnet].daw,
          bayc: CONTRACT_ADDRESSES[ChainId.Mainnet].bayc
        }
      }
    }
  },
  [ChainId.BSC]: {
    chainId: ChainId.BSC,

    start: 1660251600,
    end: 1665723600,
    closeDeposit: 1662872400,
    interval: 60 * 240, // 30 min
    rpc: "https://nodes-binance-https.wowswap.io",
    graph: graphUrl("bsc"),

    wutDecimals: 18,
    usdcDecimals: 18,

    contracts: CONTRACT_ADDRESSES[ChainId.BSC],

    alpha: {
      // WBNB-BUSD pair on pancakeswap
      pair: "0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16",
      tokens: {
        in: { ...TOKENS.wrapNative[ChainId.BSC], symbol: "BNB" },
        out: TOKENS.busd[ChainId.BSC]
      },
      DEX: {
        pancake: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
        biswap: "0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8"
      }
    },

    sale: {
      tokens: {
        assets: [TOKENS.dai[ChainId.BSC], TOKENS.busd[ChainId.BSC]],
        deposited: [
          TOKENS.dai[ChainId.BSC],
          TOKENS.busd[ChainId.BSC],
          TOKENS.usdt[ChainId.BSC],
          TOKENS.wow[ChainId.BSC],
          TOKENS.oneInch[ChainId.BSC]
        ]
      },
      chart: {
        assets: {
          wow: TOKENS.wow[ChainId.BSC].address,
          busd: TOKENS.busd[ChainId.BSC].address,
          usdc: TOKENS.usdc[ChainId.BSC].address,
          usdt: TOKENS.usdt[ChainId.BSC].address,
          dai: TOKENS.dai[ChainId.BSC].address,
          oneInch: TOKENS.oneInch[ChainId.BSC].address,
          daw: CONTRACT_ADDRESSES[ChainId.BSC].daw,
          bayc: CONTRACT_ADDRESSES[ChainId.BSC].bayc
        }
      }
    }
  },
  [ChainId.Avalanche]: {
    chainId: ChainId.Avalanche,

    start: 1660251600,
    end: 1665723600,
    closeDeposit: 1662872400,
    interval: 60 * 240, // 30 min
    // rpc: "https://nodes-avalanche-https.wowswap.io/ext/bc/C/rpc",
    rpc: "https://api.avax.network/ext/bc/C/rpc",
    graph: graphUrl("avalanche"),

    wutDecimals: 18,
    usdcDecimals: 6,

    contracts: CONTRACT_ADDRESSES[ChainId.Avalanche],

    alpha: {
      // USDC-WAVAX pair on Joe
      pair: "0xf4003F4efBE8691B60249E6afbD307aBE7758adb",
      tokens: {
        in: { ...TOKENS.wrapNative[ChainId.Avalanche], symbol: "AVAX" },
        out: TOKENS.usdc[ChainId.Avalanche]
      },
      DEX: {
        pangolin: "0xE54Ca86531e17Ef3616d22Ca28b0D458b6C89106",
        traderJoe: "0x60aE616a2155Ee3d9A68541Ba4544862310933d4"
      }
    },

    sale: {
      tokens: {
        assets: [TOKENS.dai[ChainId.Avalanche], TOKENS.usdt[ChainId.Avalanche]],
        deposited: [
          TOKENS.dai[ChainId.Avalanche],
          TOKENS.usdt[ChainId.Avalanche],
          TOKENS.usdc[ChainId.Avalanche],
          TOKENS.wow[ChainId.Avalanche],
          TOKENS.oneInch[ChainId.Avalanche]
        ]
      },
      chart: {
        assets: {
          wow: TOKENS.wow[ChainId.Avalanche].address,
          busd: TOKENS.busd[ChainId.Avalanche].address,
          usdc: TOKENS.usdc[ChainId.Avalanche].address,
          usdt: TOKENS.usdt[ChainId.Avalanche].address,
          dai: TOKENS.dai[ChainId.Avalanche].address,
          oneInch: TOKENS.oneInch[ChainId.Avalanche].address,
          daw: CONTRACT_ADDRESSES[ChainId.Avalanche].daw,
          bayc: CONTRACT_ADDRESSES[ChainId.Avalanche].bayc
        }
      }
    }
  },
  [ChainId.Polygon]: {
    chainId: ChainId.Polygon,

    start: 1660251600,
    end: 1665723600,
    closeDeposit: 1662872400,
    interval: 60 * 240, // 30 min
    // rpc: "https://nodes-polygon-https.wowswap.io",
    rpc: "https://polygon-mainnet-rpc.allthatnode.com:8545",
    graph: graphUrl("polygon"),

    wutDecimals: 18,
    usdcDecimals: 6,

    contracts: CONTRACT_ADDRESSES[ChainId.Polygon],

    alpha: {
      // WBNB-BUSD pair on pancakeswap
      pair: "0x6e7a5fafcec6bb1e78bae2a1f0b612012bf14827",
      tokens: {
        in: { ...TOKENS.wrapNative[ChainId.Polygon], symbol: "MATIC" },
        out: TOKENS.usdc[ChainId.Polygon]
      },
      DEX: {
        quickswap: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
        sushiswap: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506"
      }
    },

    sale: {
      tokens: {
        assets: [TOKENS.dai[ChainId.Polygon], TOKENS.usdt[ChainId.Polygon]],
        deposited: [
          TOKENS.dai[ChainId.Polygon],
          TOKENS.usdt[ChainId.Polygon],
          TOKENS.usdc[ChainId.Polygon],
          TOKENS.wow[ChainId.Polygon],
          TOKENS.oneInch[ChainId.Polygon]
        ]
      },
      chart: {
        assets: {
          wow: TOKENS.wow[ChainId.Polygon].address,
          busd: TOKENS.busd[ChainId.Polygon].address,
          usdc: TOKENS.usdc[ChainId.Polygon].address,
          usdt: TOKENS.usdt[ChainId.Polygon].address,
          dai: TOKENS.dai[ChainId.Polygon].address,
          oneInch: TOKENS.oneInch[ChainId.Polygon].address,
          daw: CONTRACT_ADDRESSES[ChainId.Polygon].daw,
          bayc: CONTRACT_ADDRESSES[ChainId.Polygon].bayc
        }
      }
    }
  },
  [ChainId.Hardhat]: {
    chainId: ChainId.Hardhat,

    start: 0,
    end: 0,
    closeDeposit: 0,
    interval: 60 * 240, // 30 min
    rpc: "http://127.0.0.1:8545/",
    graph: "",

    wutDecimals: 18,
    usdcDecimals: 18,

    contracts: CONTRACT_ADDRESSES[ChainId.Hardhat],

    alpha: {
      // WBNB-BUSD pair on pancakeswap
      pair: "",
      tokens: {
        in: { ...TOKENS.wrapNative[ChainId.Polygon], symbol: "MATIC" },
        out: TOKENS.usdc[ChainId.Polygon]
      },
      DEX: {
        quickswap: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
        sushiswap: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506"
      }
    },

    sale: {
      tokens: {
        assets: [TOKENS.dai[ChainId.Polygon], TOKENS.usdt[ChainId.Polygon]],
        deposited: [
          TOKENS.dai[ChainId.Polygon],
          TOKENS.usdt[ChainId.Polygon],
          TOKENS.usdc[ChainId.Polygon],
          TOKENS.wow[ChainId.Polygon],
          TOKENS.oneInch[ChainId.Polygon]
        ]
      },
      chart: {
        assets: {
          wow: TOKENS.wow[ChainId.Polygon].address,
          busd: TOKENS.busd[ChainId.Polygon].address,
          usdc: TOKENS.usdc[ChainId.Polygon].address,
          usdt: TOKENS.usdt[ChainId.Polygon].address,
          dai: TOKENS.dai[ChainId.Polygon].address,
          oneInch: TOKENS.oneInch[ChainId.Polygon].address,
          daw: CONTRACT_ADDRESSES[ChainId.Polygon].daw,
          bayc: CONTRACT_ADDRESSES[ChainId.Polygon].bayc
        }
      }
    }
  }
};

export const SALE_SUPPORTED_CHAINS: Readonly<SupportedChainId[]> = [
  ChainId.Mainnet,
  ChainId.BSC,
  ChainId.Avalanche,
  ChainId.Polygon
] as const;

export const ALPHA_SUPPORTED_CHAINS: SupportedChainId[] = [
  ChainId.Mainnet,
  ChainId.BSC,
  ChainId.Avalanche,
  ChainId.Polygon,
  ChainId.Hardhat
];
