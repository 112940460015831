import { ChainId } from "@usedapp/core";

import { TokenKey } from "models/types";

import { SupportedAppToken } from "./tokens";

export * from "./chains";
export * from "./tokens";

export const MULTICALL_V3_ADDRESS = "0xcA11bde05977b3631167028862bE2a173976CA11";

export const TOKENS_KEYS: Readonly<Record<TokenKey, Readonly<SupportedAppToken[]>>> = {
  USD: ["dai", "usdt", "usdc", "busd"],
  WOW: ["wow"],
  "1INCH": ["oneInch"]
};

export const MAX_USD_ALLOCATION = 500e3;
export const MAX_USD_ALLOCATION_HUMANLY = "500k";

export const MAX_WOW_ALLOCATION = 150e3;
export const MAX_WOW_ALLOCATION_HUMANLY = "150k";

export const MAX_ONEINCH_ALLOCATION = 150e3;
export const MAX_ONEINCH_ALLOCATION_HUMANLY = "150k";

export const MAX_DEFI_ALLOCATION_FOR_ERC20 = 800e3;

export const DAW_PRICE = 500;
export const BAYC_PRICE = 100000;
