import {
  Avalanche,
  BSC,
  Config,
  DAppProvider,
  Mainnet,
  MetamaskConnector,
  Polygon
} from "@usedapp/core";
import { WalletConnectConnector } from "@usedapp/wallet-connect-connector";
import React from "react";

import { MULTICALL_V3_ADDRESS } from "./src/constants";
import "./src/assets/css/fonts.css";

const config: Config = {
  readOnlyUrls: {
    [Mainnet.chainId]: "https://eth.llamarpc.com",
    [BSC.chainId]: "https://bsc-dataseed.binance.org/",
    [Avalanche.chainId]: "https://api.avax.network/ext/bc/C/rpc",
    [Polygon.chainId]: "https://matic-mainnet.chainstacklabs.com"
  },
  multicallAddresses: {
    [BSC.chainId]: MULTICALL_V3_ADDRESS,
    [Mainnet.chainId]: MULTICALL_V3_ADDRESS,
    [Avalanche.chainId]: MULTICALL_V3_ADDRESS,
    [Polygon.chainId]: MULTICALL_V3_ADDRESS
  },
  connectors: {
    metamask: new MetamaskConnector(),
    walletConnect: new WalletConnectConnector({ infuraId: "d8df2cb7844e4a54ab0a782f608749dd" })
  }
};

export const wrapRootElement = ({ element }) => (
  <DAppProvider config={config}>{element}</DAppProvider>
);
