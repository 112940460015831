import { ChainId } from "@usedapp/core";

import { SupportedChainId } from "./types";

export type SupportedAppToken =
  | "wut"
  | "wow"
  | "oneInch"
  | "wrapNative"
  | "usdt"
  | "usdc"
  | "busd"
  | "dai";

export type TokenInfo = {
  key: SupportedAppToken;
  name: string;
  symbol: string;
  decimals: number;
  address: string;
};

type TokenConfig = Record<SupportedChainId, TokenInfo>;

export const TOKENS: Readonly<Record<SupportedAppToken, TokenConfig>> = {
  wut: {
    [ChainId.Rinkeby]: {
      key: "wut",
      name: "WOW Universe Token",
      symbol: "WUT",
      decimals: 18,
      address: "0x84BF9c79b622327F9600605402d7Ad45d8dC8376"
    },
    [ChainId.Mainnet]: {
      key: "wut",
      name: "WOW Universe Token",
      symbol: "WUT",
      decimals: 18,
      address: "0xf1c6cFD58A019F3aa69D22296D8995181cFc40b5"
    },
    [ChainId.BSC]: {
      key: "wut",
      // TBA
      name: "",
      symbol: "",
      decimals: 8,
      address: ""
    },
    [ChainId.Avalanche]: {
      key: "wut",
      // TBA
      name: "",
      symbol: "",
      decimals: 8,
      address: ""
    },
    [ChainId.Polygon]: {
      key: "wut",
      // TBA
      name: "",
      symbol: "",
      decimals: 8,
      address: ""
    }
  },
  wow: {
    [ChainId.Rinkeby]: {
      key: "wow",
      name: "WOW",
      symbol: "WOW",
      decimals: 18,
      address: "0xdDE55160F48F14338EF18382ee08F70dE00bCF41"
    },
    [ChainId.Mainnet]: {
      key: "wow",
      name: "WOWswap",
      symbol: "WOW",
      decimals: 18,
      address: "0x3405A1bd46B85c5C029483FbECf2F3E611026e45"
    },
    [ChainId.BSC]: {
      key: "wow",
      name: "WOWswap",
      symbol: "WOW",
      decimals: 18,
      address: "0x4DA996C5Fe84755C80e108cf96Fe705174c5e36A"
    },
    [ChainId.Avalanche]: {
      key: "wow",
      name: "WOWswap",
      symbol: "WOW",
      decimals: 18,
      address: "0xA384Bc7Cdc0A93e686da9E7B8C0807cD040F4E0b"
    },
    [ChainId.Polygon]: {
      key: "wow",
      name: "WOWswap",
      symbol: "WOW",
      decimals: 18,
      address: "0x855D4248672a1fCE482165e8DBE1207b94b1968a"
    }
  },
  wrapNative: {
    [ChainId.Rinkeby]: {
      key: "wrapNative",
      name: "",
      symbol: "",
      decimals: 8,
      address: ""
    },
    [ChainId.Mainnet]: {
      key: "wrapNative",
      name: "Wrapped Ether",
      symbol: "WETH",
      decimals: 18,
      address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
    },
    [ChainId.BSC]: {
      key: "wrapNative",
      name: "Wrapped BNB",
      symbol: "WBNB",
      decimals: 18,
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
    },
    [ChainId.Avalanche]: {
      key: "wrapNative",
      name: "Wrapped AVAX",
      symbol: "WAVAX",
      decimals: 18,
      address: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7"
    },
    [ChainId.Polygon]: {
      key: "wrapNative",
      name: "Wrapped MATIC",
      symbol: "WMATIC",
      decimals: 18,
      address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270"
    }
  },
  oneInch: {
    [ChainId.Rinkeby]: {
      key: "oneInch",
      name: "1inch Token",
      symbol: "1INCH",
      decimals: 18,
      address: "0x652E18f25dFf39Ee5E2E437F9d796A0DeAad14C4"
    },
    [ChainId.Mainnet]: {
      key: "oneInch",
      name: "1INCH Token",
      symbol: "1INCH",
      decimals: 18,
      address: "0x111111111117dC0aa78b770fA6A738034120C302"
    },
    [ChainId.BSC]: {
      key: "oneInch",
      name: "1INCH Token",
      symbol: "1INCH",
      decimals: 18,
      address: "0x111111111117dC0aa78b770fA6A738034120C302"
    },
    [ChainId.Avalanche]: {
      key: "oneInch",
      name: "1INCH Token",
      symbol: "1INCH",
      decimals: 18,
      address: "0xd501281565bf7789224523144Fe5D98e8B28f267"
    },
    [ChainId.Polygon]: {
      key: "oneInch",
      name: "1INCH Token",
      symbol: "1INCH",
      decimals: 18,
      address: "0x9c2C5fd7b07E95EE044DDeba0E97a665F142394f"
    }
  },
  usdt: {
    [ChainId.Rinkeby]: {
      key: "usdt",
      name: "USDT",
      symbol: "USDT",
      decimals: 6,
      address: "0x80Dbc32E887a0acf3D4353A12CE23D748853b34E"
    },
    [ChainId.Mainnet]: {
      key: "usdt",
      name: "Tether USD",
      symbol: "USDT",
      decimals: 6,
      address: "0xdAC17F958D2ee523a2206206994597C13D831ec7"
    },
    [ChainId.BSC]: {
      key: "usdt",
      name: "Binance-Peg BSC-USD",
      symbol: "USDT",
      decimals: 18,
      address: "0x55d398326f99059fF775485246999027B3197955"
    },
    [ChainId.Avalanche]: {
      key: "usdt",
      name: "TetherToken",
      symbol: "USDT",
      decimals: 6,
      address: "0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7"
    },
    [ChainId.Polygon]: {
      key: "usdt",
      name: "(PoS) Tether USD",
      symbol: "USDT",
      decimals: 6,
      address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"
    }
  },
  usdc: {
    [ChainId.Rinkeby]: {
      key: "usdc",
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0x8BbAce1f9223F11634FfddC2BBE50F768bb28ca5"
      //  usdc: "0x3752dD6042081133a75edd370f0D510ed32FB7b5" // from chain.ts
    },
    [ChainId.Mainnet]: {
      key: "usdc",
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
      // usdc: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48" // from chain.ts
    },
    [ChainId.BSC]: {
      key: "usdc",
      name: "Binance-Peg USD Coin",
      symbol: "USDC",
      decimals: 18,
      address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"
    },
    [ChainId.Avalanche]: {
      key: "usdc",
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E"
    },
    [ChainId.Polygon]: {
      key: "usdc",
      name: "USD Coin (PoS)",
      symbol: "USDC",
      decimals: 6,
      address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174"
      // usdc: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174" // from chain.ts
    }
  },
  busd: {
    [ChainId.Rinkeby]: {
      key: "busd",
      name: "",
      symbol: "",
      decimals: 8,
      address: ""
    },
    [ChainId.Mainnet]: {
      key: "busd",
      name: "Binance USD",
      symbol: "BUSD",
      decimals: 18,
      address: "0x4Fabb145d64652a948d72533023f6E7A623C7C53"
    },
    [ChainId.BSC]: {
      key: "busd",
      name: "Binance-Peg BUSD Token",
      symbol: "BUSD",
      decimals: 18,
      address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
    },
    [ChainId.Avalanche]: {
      key: "busd",
      name: "Binance USD",
      symbol: "BUSD",
      decimals: 18,
      address: "0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98"
    },
    [ChainId.Polygon]: {
      key: "busd",
      name: "binance-usd",
      symbol: "BUSD",
      decimals: 18,
      address: "0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7"
    }
  },
  dai: {
    [ChainId.Rinkeby]: {
      key: "dai",
      name: "DAI",
      symbol: "DAI",
      decimals: 8,
      address: "0xD14d052cD342B3311e72fB1c3FAE617f538Ab360"
    },
    [ChainId.Mainnet]: {
      key: "dai",
      name: "Dai Stablecoin",
      symbol: "DAI",
      decimals: 18,
      address: "0x6B175474E89094C44Da98b954EedeAC495271d0F"
    },
    [ChainId.BSC]: {
      key: "dai",
      name: "Binance-Peg Dai Token",
      symbol: "DAI",
      decimals: 18,
      address: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3"
    },
    [ChainId.Avalanche]: {
      key: "dai",
      name: "Dai Stablecoin",
      symbol: "DAI",
      decimals: 18,
      address: "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70"
    },
    [ChainId.Polygon]: {
      key: "dai",
      name: "(PoS) Dai Stablecoin",
      symbol: "DAI",
      decimals: 18,
      address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063"
    }
  }
};
